export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';



//Auth Actions
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'

//Register User
export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'

//Forgot Password
export const SET_RESET_TOKEN = 'SET_RESET_TOKEN'
export const FORGOT_PASS_SUCCESS = 'FORGOT_PASS_SUCCESS'
export const RESET_PASS_SUCCESS = 'RESET_PASS_SUCCESS'

//Logout
export const LOGOUT_USER = 'LOGOUT_USER'

//Add Target Modal
export const TOGGLE_ADD_TARGET_MODAL = "TOGGLE_ADD_TARGET_MODAL"
export const TOGGLE_TIME_EDIT_MODAL = "TOGGLE_TIME_EDIT_MODAL"

//Timer
export const UPDATE_TIMER = 'UPDATE_TIMER'
export const RESET_TIMER = 'RESET_TIMER'
export const SET_TIMER = 'SET_TIMER'

//Error Notifier
export const TOGGLE_TOASTER = "TOGGLE_TOASTER"

//Calendar Events
export const GET_EVENTS = 'GET_EVENTS'
export const SET_EVENTS_SUCCESS = 'SET_EVENTS_SUCCESS'

//GET ALL LAWYERS
export const GET_LAWYERS = 'GET_LAWYERS'
export const SET_LAWYERS = 'SET_LAWYERS'

export const SELECT_LAWYER = 'SELECT_LAWYER'

export const BLOCK_USER = "BLOCK_USER"
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS'

export const UNBLOCK_USER = "UNBLOCK_USER"
export const UNBLOCK_USER_SUCCESS = 'UNBLOCK_USER_SUCCESS'


//Blogs Manage
export const SET_BLOGS = 'SET_BLOGS'
export const SELECT_BLOG = 'SELECT_BLOG'

export const CREATE_BLOG_SUCCESS = 'CREATE_BLOG_SUCCESS'

export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS'

export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS'

//Features
export const SET_FEATURES = 'SET_FEATURES'
export const SELECT_FEATURE = 'SELECT_FEATURE'


export const CREATE_FEATURE_SUCCESS = 'CREATE_FEATURE_SUCCESS'

export const UPDATE_FEATURE_SUCCESS = 'UPDATE_FEATURE_SUCCESS'

export const DELETE_FEATURE_SUCCESS = 'DELETE_FEATURE_SUCCESS'

//PLANS
export const SET_PLANS = 'SET_PLANS'
export const SELECT_PLAN = 'SELECT_PLAN'
export const CREATE_PLAN_SUCCESS = 'CREATE_PLAN_SUCCESS'
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS'
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS'

//Contacts
export const SET_CONTACTS = 'SET_CONTACTS'
export const SELECT_CONTACT = 'SELECT_CONTACT'
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS'
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS'
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS'





