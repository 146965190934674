import React from 'react';
import { Spin } from 'antd';
import Loadable from "react-loadable";
import Loader from './App/layout/Loader';

const HomePage = React.lazy(()=>import('./App/containers/HomePage'))
const Terms = React.lazy(()=>import('./App/containers/Terms/index'))
const LoginPage = React.lazy(()=>import('./App/containers/Auth/Login'))
const Registration = React.lazy(()=>import('./App/containers/Auth/registration'))
const ForgotPass = React.lazy(()=>import('./App/containers/Auth/ForgotPass'))
const ResetPass = React.lazy(()=>import('./App/containers/Auth/resetpass'))
const BlogCard = React.lazy(()=>import('./App/containers/Auth/blogcard'))
const BlogPage = React.lazy(()=>import('./App/containers/Auth/blogpage'))
const Features = React.lazy(()=>import('./App/containers/Auth/features'))
const Contact = React.lazy(()=>import('./App/containers/Auth/contactus'))
const Subscription = React.lazy(()=>import('./App/containers/Auth/subscription'))
const AdminRegister = React.lazy(() => import('./Demo/Authentication/SignUp/SignUp1'));
const AdminLogin = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn1'));
const About = React.lazy(()=>import('./App/containers/Auth/Aboutus'))
const Development = React.lazy(()=>import('./App/containers/Auth/Development'))
const Goals = React.lazy(()=>import('./App/containers/Auth/Goals'))


const route = [
    { path: '/', exact: true, name: 'HomePage', component: HomePage },
    { path: '/terms', exact: true, name: 'Terms and Conditions', component: Terms },
    { path: '/Privacypolicy', exact: true, name: 'Terms and Conditions', component: Terms },
    { path: '/login', exact: true, name: 'Login', component: LoginPage },
    { path: '/registration', exact: true, name: 'Registration', component: Registration },
    { path: '/forgot', exact: true, name: 'Forgot', component: ForgotPass },
    { path: '/setpassword', exact: true, name: 'Reset', component: ResetPass },
    { path: '/blog', exact: true, name: 'Signin 1', component: BlogCard },
    { path: '/blogpage', exact: false, name: 'Signin 1', component: BlogPage },
    { path: '/features', exact: true, name: 'Signin 1', component: Features },
    { path: '/contact', exact: true, name: 'Signin 1', component: Contact },
    { path: '/admin', exact: true, name: 'Signin 1', component: AdminLogin },
    { path: '/admin/register', exact: true, name: 'Signin 1', component: AdminRegister },
    { path: '/about', exact: true, name: 'About', component:About },
    { path: '/development', exact: true, name: 'Development', component:Development},
    { path: '/goal', exact: true, name: 'Goals', component:Goals}

  
];

const AdminLayout = Loadable({
    loader: () => import('./App/layout/AdminLayout'),
    loading: Loader,
  });

  const LawyerLayout = Loadable({
    loader: () => import('./App/layout/LawyerLayout'),
    loading: Loader,
  });



  
  

// const AdminLayout = React.lazy(() => import('./App/layout/AdminLayout'));
// const LawyerLayout = React.lazy(() => import('./App/layout/LawyerLayout'));

export const adminroute = [
    {path:'/' , exact:false, name:'AdminLayout',component:AdminLayout},
]

export const lawyerroute = [
    {path:'/' , exact:false, name:'LawyerLayout',component:LawyerLayout},

]



export default route;